import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import ButtonPrimary from "../components/ButtonPrimary";

const MdxLayout = (props) => (
  <>
    <Layout>
      <header className="mb-8">
        <Link to="/">
          <ButtonPrimary text=".. back"></ButtonPrimary>
        </Link>
      </header>
      <article className="prose prose-xl mx-auto mb-10">
        {props.children}
      </article>
    </Layout>
  </>
);

export default MdxLayout;
