import React from "react";

const ButtonPrimary = ({ text }) => {
  return (
    <button className="bg-transparent text-black px-4 py-3 rounded-lg border border-black  hover:bg-black hover:text-white ">
      {text}
    </button>
  );
};

ButtonPrimary.defaultProps = {
  color: "bg-gray-100",
  text: "button",
};

export default ButtonPrimary;
