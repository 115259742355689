import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MdxLayout.js";
import Seo from "../../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Winery" mdxType="Seo" />
    <h1>{`Launching a winery`}</h1>
    <p>{`Archibald James is a boutique producer of minimal-intervention wines and ciders. We handcraft delicious beverages from the finest fruit in Washington state.`}</p>
    <h2>{`Innovative products`}</h2>
    <p>{`We carved out our niche in the cider market, developing a `}<strong parentName="p">{`100%-apple beverage that surprises and delights wine lovers`}</strong>{`. Too often, ciders are sweet and uniform, lacking the complexity and depth of character we expect from a great wine. And too often, ciders are made and marketed next to beer when, in reality, cider is wine—just substitute apples for grapes. We saw the potential for a delicious, high-quality cider to overcome this stasis and evolve the market.`}</p>
    <p>{`Customers love our ciders, and we pressed forward in 2018 with an array of products to trial various styles: dozens of apple varieties and yeast strains, aging up to 18 months in oak and stainless barrels, co-fermenting with fruits and with herbs. Iterating according to customer feedback, we settled on a stylistic profile that plays off our wine-adjacency and allows us to experiment with small batches of delicious and exciting products that offer unique and surprising experiences to our customers.`}</p>
    <p>{`When our grape wines rolled out in 2020, our category-crossing strategy yielded exciting results. The wine offering widened our net, `}<strong parentName="p">{`drawing in customers from the better-established wine market, earning their trust with an excellent product, then cross-selling a surprisingly delicious cider`}</strong>{`.`}</p>
    <p>{`Our wines are wonderful, and we’re just getting started. Building off the success of our cider business, we applied the same product philosophy: start with the highest quality fruit, apply a minimal-intervention production process, and deliver a delicious wine that improves with age.`}</p>
    <p>{`Our wines highlight the unique aspects of the grape variety and the place it was grown, focusing on balance and nuance rather than heavy extraction and high alcohol. Instead of imposing an overarching style across our lineup, we bring out the best in each batch. Our customers therefore enjoy an evolving variety of styles in our portfolio—it’s never boring.`}</p>
    <p>{`We continue to experiment and push the limits of the established wine and cider market, introducing customers to delicious products that celebrate the fantastic fruit grown in our backyard.`}</p>
    <h2>{`Unique business model`}</h2>
    <p>{`Wines take a few years to make, so `}<strong parentName="p">{`rapid iteration isn't possible for most producers`}</strong>{`. Our first reds needed three years before bottling and won't peak for several years after that. Meanwhile each vintage requires a major production investment, still without customer feedback.`}</p>
    <p>{`Cider, on the other hand, has shorter development cycles. We're pushing the limits of cider aging, yet most of our products are ready within one year. By producing small batches in a wide variety of styles, we're able to rapidly test apple-based products with the same target audience as our grape wines. Better still, we chill apples after harvest, giving ourselves year-round access to top-quality fruit and the `}<strong parentName="p">{`ability to stagger product development and release year-round`}</strong>{`, without disrupting wine production. For us, this means better labor management, more consistent inventory, higher equipment utilization, and the ability to experiment stylistically without overcommitment to one product. For our customers, it means exciting new product releases on a continuous basis.`}</p>
    <p>{`With `}<strong parentName="p">{`continuous development and release cycles`}</strong>{`, we can solicit customer feedback often and isolate the technical variables that succeed in the market. Knowing what our current customers like lets us continue to produce appealing products, while also pushing the market forward and strategically expanding our lineup to target new segments.`}</p>
    <p>{`Learn more at `}<a parentName="p" {...{
        "href": "https://archibaldjames.com"
      }}>{`archibaldjames.com`}</a>{`.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAEDBAUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGtNEs76KgmnmpCGgV//8QAHBAAAgMBAAMAAAAAAAAAAAAAAhEBAxIAEyIy/9oACAEBAAEFAhhdDgDmvVbwTW5708dhzoRXPNl31XKD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/AWrWP//EABcRAAMBAAAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BMEp//8QAHxAAAgIBBAMAAAAAAAAAAAAAAAECESEDElGBIkFx/9oACAEBAAY/AuXwZPKTs1J9Em3gyrNqfZSvJUrsnRH4KveT/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARITFBYf/aAAgBAQABPyGog5LYJCmO3LChF1JNUlWAOewow+0hv9belhUgBqUUKHRyWu0oYsxjGJeaW9Z//9oADAMBAAIAAwAAABDUPD7/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREQIf/aAAgBAwEBPxCRcxVQQ//EABYRAQEBAAAAAAAAAAAAAAAAAAAREP/aAAgBAgEBPxDEFf/EACAQAQACAgEEAwAAAAAAAAAAAAERMQAhUUFxkbGBocH/2gAIAQEAAT8QgpJs3JDEP35yZT429BPGsQBuxl/MSdBAuOsN17ybZEQjtcwVkMJFTFO47+cLDDnxh3wgxrXPrFAEBFaU6vAWakBpEjHIxTaI3GPMDI2pbn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Grape bunch",
          "title": "Grape bunch",
          "src": "/static/f0ffcc546c9c21a9150b80ba63145629/4b190/grappe.jpg",
          "srcSet": ["/static/f0ffcc546c9c21a9150b80ba63145629/e07e9/grappe.jpg 200w", "/static/f0ffcc546c9c21a9150b80ba63145629/066f9/grappe.jpg 400w", "/static/f0ffcc546c9c21a9150b80ba63145629/4b190/grappe.jpg 800w", "/static/f0ffcc546c9c21a9150b80ba63145629/e5166/grappe.jpg 1200w", "/static/f0ffcc546c9c21a9150b80ba63145629/b17f8/grappe.jpg 1600w", "/static/f0ffcc546c9c21a9150b80ba63145629/9568a/grappe.jpg 3024w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAMEAQIF/8QAGAEAAgMAAAAAAAAAAAAAAAAAAAECAwT/2gAMAwEAAhADEAAAAWs59qKiUZh635r9BxE//8QAHBAAAgICAwAAAAAAAAAAAAAAAQIAEQMSECMx/9oACAEBAAEFAidS8XVhkPf4YWrJUoTVS3H/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREQ/9oACAEDAQE/AchEf//EABkRAAIDAQAAAAAAAAAAAAAAAAABAhASIf/aAAgBAgEBPwGnLiNM/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMQIQIVH/2gAIAQEABj8CWPpwkV8XBRq0UUS0p3//xAAdEAADAQACAwEAAAAAAAAAAAAAASERUXExQWGB/9oACAEBAAE/IZwmt3gynS8DRl+mOC4BGaI7HXb0PyWeXS1YPuLRJ7aEJn//2gAMAwEAAgADAAAAEA8DM//EABgRAQEBAQEAAAAAAAAAAAAAAAEAIRFh/9oACAEDAQE/EHLsbVvK/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARIWH/2gAIAQIBAT8QCyVA5l73/8QAHhABAAIDAAIDAAAAAAAAAAAAAQARITFBUYGRodH/2gAIAQEAAT8QGp1dOaV85SDGFZi47Cxu0mCJFAai3RbfX5KrRU7dvD9wtgF1pW+DqJBCXRv3iCQWxWHk8nsGeIyWEipxErc//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Archibald James Cider",
          "title": "Archibald James Cider",
          "src": "/static/5f96da44ed92f63c8d47dbe776158d97/4b190/cider-tree.jpg",
          "srcSet": ["/static/5f96da44ed92f63c8d47dbe776158d97/e07e9/cider-tree.jpg 200w", "/static/5f96da44ed92f63c8d47dbe776158d97/066f9/cider-tree.jpg 400w", "/static/5f96da44ed92f63c8d47dbe776158d97/4b190/cider-tree.jpg 800w", "/static/5f96da44ed92f63c8d47dbe776158d97/e5166/cider-tree.jpg 1200w", "/static/5f96da44ed92f63c8d47dbe776158d97/b17f8/cider-tree.jpg 1600w", "/static/5f96da44ed92f63c8d47dbe776158d97/9568a/cider-tree.jpg 3024w"],
          "sizes": "(max-width: 800px) 100vw, 800px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      